import PodcastCard, {
  LISTEN_ON_SPOTIFY,
  WATCH_ON_YOUTUBE,
} from '@/components/shared/Post/Podcast'
import { useBreakpoints } from '@/hooks/useBreakpoints'

// Reuse Podcast Link component
function PodcastLink({
  text,
  type,
}: {
  text: string
  type: 'youtube' | 'spotify'
}) {
  return (
    <PodcastCard.PodcastLink
      text={text}
      type={type}
      className="text-black transition duration-300 border-black lg:text-gray-400 lg:border-gray-400 group lg:hover:border-blue-500"
      textClassName="subheading-6 md:heading-6 lg:subheading-6 group-hover:text-blue-500 transition duration-300"
      arrowClassName="stroke-black lg:stroke-gray-400 group-hover:stroke-blue-500 transition duration-300"
    />
  )
}

const Podcasts = ({ podcasts }: any) => {
  const { isMobile, isTablet } = useBreakpoints()
  // get first podcast is featured podcast
  const featuredPodcast = podcasts[0]
  // other podcasts
  const otherPodcasts = podcasts.slice(1)
  return (
    <div className="space-y-0 base-container bg-gray-50">
      {/* Mobile and tablet only */}
      <div className="block lg:hidden">
        <h1 className="block section-heading">Podcast</h1>
        <div className="grid grid-cols-1 gap-y-6">
          {podcasts &&
            podcasts.map((podcast: any, index: number) => (
              <PodcastCard
                key={index}
                title={podcast.title}
                youtubeLink={podcast.vhl_fields.podcastYoutubeLink}
                spotifyLink={podcast.vhl_fields.podcastSpotifyLink}
                featuredImage={
                  podcast.featuredImage.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                className="flex flex-col space-y-3"
              >
                <>
                  <PodcastCard.Title className="md:heading-5 heading-6" />
                  {isMobile && (
                    <PodcastCard.FeaturedImage isGatsbyImage openYoutubeLink />
                  )}
                  {isTablet && <PodcastCard.YoutubeVideo />}
                  <div className="flex justify-end space-x-12">
                    {isTablet && (
                      <PodcastLink type="youtube" text={WATCH_ON_YOUTUBE} />
                    )}
                    <PodcastLink type="spotify" text={LISTEN_ON_SPOTIFY} />
                  </div>
                </>
              </PodcastCard>
            ))}
        </div>
      </div>
      {/* END Mobile and tablet only */}

      {/* Desktop only */}
      <div className="flex-col hidden space-y-16 lg:flex">
        <div className="flex items-start justify-between space-x-4">
          <h1 className="w-1/3 section-heading">Podcast</h1>
          {/* Featured Podcast */}
          <div className="flex flex-col w-full">
            <PodcastCard
              title={featuredPodcast.title}
              youtubeLink={featuredPodcast.vhl_fields.podcastYoutubeLink}
              spotifyLink={featuredPodcast.vhl_fields.podcastSpotifyLink}
              className="flex flex-col space-y-4"
            >
              <>
                <PodcastCard.YoutubeVideo className="mt-6" />
                <div className="flex justify-between">
                  <PodcastCard.Title className="heading-5" openYoutubeLink />
                  <div className="flex space-x-8">
                    <PodcastLink type="youtube" text={WATCH_ON_YOUTUBE} />
                    <PodcastLink type="spotify" text={LISTEN_ON_SPOTIFY} />
                  </div>
                </div>
              </>
            </PodcastCard>
          </div>
        </div>
        {/* Other podcasts */}
        <div className="grid grid-cols-4 gap-x-3 gap-y-6">
          {otherPodcasts &&
            otherPodcasts.map((podcast: any, index: number) => (
              <PodcastCard
                key={index}
                title={podcast.title}
                youtubeLink={podcast.vhl_fields.podcastYoutubeLink}
                spotifyLink={podcast.vhl_fields.podcastSpotifyLink}
                featuredImage={
                  podcast.featuredImage.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                className="flex flex-col space-y-2"
              >
                <>
                  <PodcastCard.FeaturedImage isGatsbyImage openYoutubeLink />
                  <PodcastCard.Title
                    className="pb-2 heading-6"
                    openYoutubeLink
                  />
                  <PodcastLink type="spotify" text={LISTEN_ON_SPOTIFY} />
                </>
              </PodcastCard>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Podcasts
